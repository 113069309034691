import React from 'react'

export const Social = () => {
  return (
    <div className="home__social">
        <a href="https://www.linkedin.com/in/alexandremerigot/" className="home__social-icon" target ="_blank">
            <i class="uil uil-linkedin"></i>   
        </a>

        <a href="https://github.com/AlexMerigot" className="home__social-icon" target ="_blank">
            <i class="uil uil-github"></i>
        </a>

        <a href="mailto:alexandre.merigot@outlook.com" className="home__social-icon" target ="_blank">
            <i class="uil uil-fast-mail"></i>
        </a>
    </div>
  )
}

export default Social