import React from 'react'

const Backend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">BACKEND</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">MySQL</h3>
                    <span className="skills__level">Intermediaire</span>
                </div>
                </div>

                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">Python</h3>
                    <span className="skills__level">Intermediaire</span>
                </div>
                </div>

                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">Node Js</h3>
                    <span className="skills__level">Basique</span>
                </div>
                </div>
            </div>

            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-badge-check'></i>
                <div>
                    <h3 className="skills__name">Java</h3>
                    <span className="skills__level">Basique</span>
                </div>
                </div>

                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">API</h3>
                    <span className="skills__level">Basique</span>
                </div>
                </div>

                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">Django</h3>
                    <span className="skills__level">Basique</span>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Backend