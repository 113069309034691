import React from 'react';
import "./footer.css";


export const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container">
            <h1 className="footer__title"> Alexandre Mérigot</h1>
            {/* <ul className="footer__list">
                <li>
                    <a href="#about" 
                    className="footer__link">A propos de moi</a>
                </li>
                <li>
                    <a href="#portfolio" 
                    className="footer__link">Portfolio</a>
                </li>
            </ul> */}

            <div className="footer__social">
                <a href="https://www.linkedin.com/in/alexandremerigot/" 
                className="footer__social-link" 
                target ="_blank">
                    <i class="uil uil-linkedin"></i>   
                </a>

                <a href="https://github.com/AlexMerigot" 
                className="footer__social-link" 
                target ="_blank">
                    <i class='bx bxl-github'></i>
                </a>
            </div>

            <span className="footer__copy">
            &#169; Alexandre Merigot. All rights reserved.
            </span>
        </div>
    </footer>
  )
}

export default Footer
