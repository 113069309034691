import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./contact.css";

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
            'service_46tjznh', 
            'template_1dbkpkr', 
            form.current, 
            '_uXCmLUATEAfEXL8W')
        e.target.reset()
    };
  
    
  return (
    <section className="contact section" id="contact">
        <h2 className="section__title">Contact</h2>
        <span className="section__subtitle">Pour me contacter </span>

        <div className="contact__container container grid">
            <div className="contact__content">
                <h3 className="contact__title">Parler avec moi </h3>

                <div className="contact__info">
                    <div className="contact__card">
                        <i className="bx bx-mail-send contact__card-icon"></i>

                        <h3 className="contact__card-title">Email</h3>
                        <span className="contact__card-data">alexandre.merigot@outlook.com</span>

                        <a href="mailto:alexandre.merigot@outlook.com" className="contact__button">Ecrivez moi<i className='bx bx-right-arrow-alt contact__button-icon'></i></a>

                    </div>

                    <div className="contact__card">
                        <i className="bx bxl-linkedin-square contact__card-icon"></i>

                        <h3 className="contact__card-title">LinkedIn</h3>

                        <a href="https://www.linkedin.com/in/alexandremerigot/" className="contact__button">Ecrivez moi <i className='bx bx-right-arrow-alt contact__button-icon'></i></a>

                    </div>
                </div>
            </div>

            <div className="contact__content">
                <h3 className="contact__title">Votre projet</h3>

                <form ref={form} onSubmit={sendEmail} className="contact__form">
                    <div className="contact__form-div">
                        <label className="contact__form-tag">Nom</label>
                        <input 
                        type="name" 
                        name="name" 
                        className="contact__form-input" 
                        placeholder="Insérer votre nom" />
                    </div>

                    <div className="contact__form-div">
                        <label className="contact__form-tag">Mail</label>
                        <input 
                        type="email" 
                        name="email" 
                        className="contact__form-input" 
                        placeholder="Insérer votre email" />
                    </div>

                    <div className="contact__form-div contact__form-area">
                        <label className="contact__form-tag">Projet</label>
                        <textarea 
                        name="project" 
                        cols="30" 
                        rows="10" 
                        className='contact__form-input' 
                        placeholder='Ecrivez votre projet'></textarea>
                    </div>

                    <button className="button button--flex">
                        <div style={{marginRight: ".5rem"}}>Envoyer le message</div>
                        <i class="uil uil-message"></i>
                    </button>
                </form>
            </div>
        </div>
    </section>
  )
}

export default Contact