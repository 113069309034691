import React, { useState } from 'react';
import "./header.css";

const Header = () => {
    /*=============== Change Backgroud Header ==============*/
    window.addEventListener("scroll", function () {
        const header = document.querySelector(".header");
        if (this.scrollY >= 80) header.classList.add("scroll-header");
        else header.classList.remove("scroll-header");
    })
    /*=============== Toggle Menu ==============*/
    const[Toggle, showMenu] = useState(false);
    const [activeNav, setActiveNav] = useState("#home");

    /*=============== Toggle Darkmode  ==============*/
    const[toggled, setToggled] = useState(false);
    
    const darkMode = () => {
        let darkMode = document.querySelector(':root');
        if (toggled) {
            darkMode.style.setProperty(
                "--title-color","hsl(var(--hue), var(--sat), 100%)"
            ); 
            darkMode.style.setProperty(
                "--title-color-dark","hsl(var(--hue), var(--sat), 70%)"
            ); 
            darkMode.style.setProperty(
                "--text-color","hsl(var(--hue), var(--sat), 80%)"
            ); 
            darkMode.style.setProperty(
                "--body-color","rgb(28, 28, 44)"
            ); 
            darkMode.style.setProperty(
                "--container-color","rgb(28, 28, 70)"
            ); 
            darkMode.style.setProperty(
                "--image-color","invert(0)"
            ); 
        } else {
        darkMode.style.setProperty(
            "--title-color","hsl(var(--hue), var(--sat), 20%)"
        ); 
        darkMode.style.setProperty(
            "--title-color-dark","hsl(var(--hue), var(--sat), 0%)"
        ); 
        darkMode.style.setProperty(
            "--text-color","hsl(var(--hue), var(--sat), 46%)"
        ); 
        darkMode.style.setProperty(
            "--body-color","hsl(var(--hue), var(--sat), 98%)"
        ); 
        darkMode.style.setProperty(
            "--container-color","rgb(255, 255, 255)"
        ); 
        darkMode.style.setProperty(
            "--image-color","invert(100%)"
        ); 
        }
        setToggled(!toggled)
    }



  return (
    <header className="header">
        <nav className="nav container">
            <a href="index.html" className="nav__logo">
            Mérigot
            </a>

            <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                <ul className="nav__list grid">
                    <li className="nav__item">
                        <a href="#home" 
                        onClick={() => setActiveNav("#home")} 
                        className={
                            activeNav === '"#home' ? "nav__link active-link"
                            : "nav__link"
                        }
                        >
                            <i className="uil uil-estate nav__icon"></i> Accueil
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#about" onClick={() => setActiveNav("#about")} 
                        className={
                            activeNav === '"#about' ? "nav__link active-link"
                            : "nav__link"
                        }
                        >
                        <i className="uil uil-user nav__icon"></i> A propos
                        </a>
                    </li>

                    <li className="nav__item">
                    <a href="#skills" onClick={() => setActiveNav("#skills")} 
                        className={
                            activeNav === '"#skills' ? "nav__link active-link"
                            : "nav__link"
                        }
                        >
                            <i className="uil uil-file-alt nav__icon"></i> Compétences
                        </a>
                    </li>

                    {/* <li className="nav__item">
                    <a href="#portfolio" onClick={() => setActiveNav("#portfolio")} 
                        className={
                            activeNav === '"#portfolio' ? "nav__link active-link"
                            : "nav__link"
                        }
                        >
                            <i className="uil uil-scenery nav__icon"></i> Portfolio
                        </a>
                    </li> */}

                    <li className="nav__item">
                    <a href="#contact" onClick={() => setActiveNav("#contact")} 
                        className={
                            activeNav === '"#contact' ? "nav__link active-link"
                            : "nav__link"
                        }
                        >
                            <i className="uil uil-message nav__icon"></i> Contact
                        </a>
                    </li>

                    <li className="nav__item light_dark" onClick={() => darkMode()}>
                            <i className="uil uil-sun"></i>
                    </li>
                </ul>

                <i className="uil uil-times nav__close" onClick={() => showMenu (!Toggle)}
                ></i>
            </div>

            <div className="nav__toggle" onClick={() => showMenu (!Toggle)}>
                <i className="uil uil-apps"></i>
            </div>
        </nav>
    </header>
  )
}

export default Header