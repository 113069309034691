import React, { useState } from 'react';
import "./qualification.css";

const Qualification = () => {
    const[toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };
  return (
    <section className="qualification section">
        <h2 className="section__title">Qualification</h2>
        <span className="section__subtitle">Mon Parcours personnel</span>

        <div className="qualification__container container">
            <div className="qualification__tabs">
                <div className={
                    toggleState === 1 
                    ? "qualification__button qualification__active button--flex" 
                    : "qualification__button button--flex"}
                    onClick={() => toggleTab(1)}>
                    
                    <i className="uil uil-graduation-cap qualification__icon"></i>
                    Formation
                </div>

                <div className={
                    toggleState === 2 
                    ? "qualification__button qualification__active button--flex" 
                    : "qualification__button button--flex"}
                    onClick={() => toggleTab(2)}>
                    <i className="uil uil-briefcase-alt qualification__icon"></i>
                    Experience
                </div>
            </div>

            <div className="qualification__sections">
                <div className={
                    toggleState === 2 
                    ? "qualification__content qualification__content-active" 
                    : "qualification__content"}>
                    <div className="qualification__data">
                        <div>
                            <h3 className="qualification__title">Alternance - Développeur Fullstack</h3>
                            <span className="qualification__subtitle"> 
                                France - Data pulse
                            </span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i> 
                                2022 - Présent
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                            <div></div>

                            <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">Responsable Developpement</h3>
                            <span className="qualification__subtitle"> 
                                France - Transports Mérigot
                            </span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i> 
                                2020 - 2022
                            </div>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div>
                            <h3 className="qualification__title">Chef de secteur GMS</h3>
                            <span className="qualification__subtitle"> 
                                France - Groupe Havea
                            </span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i> 
                                2019 - 2020
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                            <div></div>

                            <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">Alternance - Marketing</h3>
                            <span className="qualification__subtitle"> 
                                France - Michelin
                            </span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i> 
                                2016 - 2018
                            </div>
                        </div>
                    </div>
                </div>

                <div className={
                    toggleState === 1 
                    ? "qualification__content qualification__content-active" 
                    : "qualification__content"}>
                    <div className="qualification__data">
                        <div>
                            <h3 className="qualification__title">Msc Pro - Développement Informatique</h3>
                            <span className="qualification__subtitle"> 
                                France - Epitech Toulouse
                            </span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i> 
                                2022 - 2025
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                            <div></div>

                            <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">MBA Marketing / Communication</h3>
                            <span className="qualification__subtitle"> 
                                France - ESCO Wesford Clermont-Ferrand
                            </span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i> 
                                2016 - 2018
                            </div>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div>
                            <h3 className="qualification__title">Licence Banque Assurance Finance</h3>
                            <span className="qualification__subtitle"> 
                                France - IUT de Limoges
                            </span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i> 
                                2015 - 2016
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                            <div></div>

                            <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">DUT Tech de Co.</h3>
                            <span className="qualification__subtitle"> 
                                France - IUT de Limoges
                            </span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i> 
                                2012 - 2014
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </section>
  )
}

export default Qualification