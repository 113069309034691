import React from 'react'

const Data = () => {
  return (
    <div className="home__data">
        <h1 className="home__title">Alexandre Mérigot
        </h1>
        <h3 className="home__subtitle">Developpeur</h3>
        <p className="home__description">Hey, je suis un développeur basé à Toulouse. Je suis passionné par la Blockchain et les nouvelles technologies.</p>
        <a href="#contact" className="button button--flex">
            <div style={{marginRight: ".5rem"}}>Dites moi Bonjour</div>
            <i class="uil uil-message"></i>
        </a>
    </div>
  )
}

export default Data