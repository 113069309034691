import React from 'react';
import "./about.css";
import about1 from "../../assets/about1.jpg"
import CV from "../../assets/CV.pdf";
import Info from './Info';


const About = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">Qui suis-je ?</h2>
      <span className="section__subtitle">Ma présentation</span>
    <div className="about__container container grid">
      <img src={about1} alt="" className="about__img" />
      <div className="about__data">
        <Info />
        <p className="about__description">Developpeur Fullstack, je m'interesse aujourd'hui a toutes les technologies de ruptures, celles qui seront au coeur du monde de demain. J'essaie de vous aider à progresser sur la data Science ou la Blockchain. </p>

        <a download="" href={CV} className="button button--flex" >
        <div style={{marginRight: ".5rem"}}>Téléchargez mon CV</div> 
        <i class="uil uil-book-medical"></i>
        </a>
      </div>
    </div>
    </section>
  )
}

export default About